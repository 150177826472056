import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';

const App = () => {
  const [posts, setPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const getPosts = async () => {
    try {
      const { data } = await axios.get('https://workers.vishalkumarvasnani.workers.dev/posts');
      data.sort((a, b) => {
        const aDate = new Date(a.timestamp);
        const bDate = new Date(b.timestamp);
        return aDate - bDate;
      });
      data.reverse();
      setPosts(data);
    } catch (err) {
      // handle errors much efficiently
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  const handleModalOpenState = (state) => {
    setIsModalOpen(state);
  };

  const handleNewPost = async (title, content) => {
    try {
      const postData = {
        title,
        content,
        username: 'vishalkumarvasnani',
      };
      await axios({
        method: 'post',
        url: 'https://workers.vishalkumarvasnani.workers.dev/posts',
        data: postData,
      });
      setTimeout(async () => {
        await getPosts();
        setIsModalOpen(false);
        setIsFormSubmitted(false);
      }, 3000);
    } catch (err) {
      // handle errors much efficiently
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <div className="App">
      <LandingPage
        posts={posts}
        isModalOpen={isModalOpen}
        handleModalOpenState={(state) => handleModalOpenState(state)}
        handleNewPost={(title, content) => handleNewPost(title, content)}
        isFormSubmitted={isFormSubmitted}
        setIsFormSubmitted={(state) => setIsFormSubmitted(state)}
      />
    </div>
  );
};

export default App;
