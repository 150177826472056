import './Header.css';
import logo from '../../assets/favicon.png';

const Header = () => (
  <div className="Header">
    <div className="Header-logo">
      <div className="Header-logo-img"><img src={logo} alt="logo" /></div>
      <div id="Header-name" className="Header-logo-content"><div>Cloudflare Social Media Platform</div></div>

    </div>
    <div className="Header-user">
      Vishal Kumar Vasnani
    </div>
  </div>
);

export default Header;
