import React from 'react';
import PropTypes from 'prop-types';
import './Card.css';

const Card = ({ post }) => {
  const {
    title, username, content, timestamp,
  } = post;

  return (
    <div className="Card">
      <div className="Card-user">
        <div className="Card-user-icon">
          <span className="material-icons">face</span>
        </div>
        <div>{`@${username} | ${timestamp}`}</div>
      </div>
      <div className="Card-user-title">{title}</div>
      <div>{content}</div>
    </div>
  );
};

Card.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
};

export default Card;
