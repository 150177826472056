import './Footer.css';
import github from '../../assets/github.png';
import stackoverflow from '../../assets/stackoverflow.png';
import linkedin from '../../assets/linkedin.png';

const Footer = () => (
  <div className="Footer">
    <div className="Footer-img-box">
      <a
        href="https://github.com/vishal9950"
        target="_blank"
        rel="noreferrer"
      >
        <img className="Footer-img" src={github} alt="github" />
      </a>
    </div>
    <div className="Footer-img-box">
      <a
        href="https://www.linkedin.com/in/vishal-vasnani/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="Footer-img" src={linkedin} alt="linkedin" />
      </a>
    </div>
    <div className="Footer-img-box">
      <a
        href="https://stackoverflow.com/users/6212082/vishal-vasnani"
        target="_blank"
        rel="noreferrer"
      >
        <img className="Footer-img" src={stackoverflow} alt="stackoverflow" />
      </a>
    </div>
  </div>
);

export default Footer;
