import './ModalComponent.css';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useState } from 'react';

const ModalComponent = ({
  isModalOpen, handleModalClose, handleNewPost, isFormSubmitted, setIsFormSubmitted,
}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: '#0d1117',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleOnChangeContent = (event) => {
    setContent(event.target.value);
  };

  const handleNewPostPreCheck = (inputTitle, inputContent) => {
    if (inputTitle === '' || inputContent === '') {
      // eslint-disable-next-line no-alert
      alert('Title or Content cannot be empty');
    } else {
      setTitle('');
      setContent('');
      setIsFormSubmitted(true);
      handleNewPost(inputTitle, inputContent);
    }
  };

  const handleModalCloseClearText = () => {
    setTitle('');
    setContent('');
    handleModalClose();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
    >
      {isFormSubmitted ? <div className="Modal-form">Posting...</div>
        : (
          <div className="Modal-form">
            <div className="Modal-close">
              <button className="Modal-close-btn" type="button" onClick={() => handleModalCloseClearText()}>
                <span className="material-icons">
                  close
                </span>
              </button>
            </div>
            <div className="Modal-form-content">
              <div>Title</div>
              <div><input onChange={(event) => handleOnChangeTitle(event)} content={title} className="Modal-form-content-input" type="text" placeholder="" /></div>
              <div>Content</div>
              <div><textarea onChange={(event) => handleOnChangeContent(event)} content={content} className="Modal-form-content-input" type="text" placeholder="" cols="50" rows="5" /></div>
              <div className="Modal-form-content-btn-box">
                <button className="Modal-form-content-btn" type="submit" onClick={() => handleNewPostPreCheck(title, content)}>
                  Post
                </button>
              </div>
            </div>
          </div>
        )}
    </Modal>
  );
};

ModalComponent.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleNewPost: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  setIsFormSubmitted: PropTypes.func.isRequired,
};

export default ModalComponent;
