import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';
import Card from '../../components/Card/Card';
import Header from '../../components/Header/Header';
import './LandingPage.css';
import Footer from '../../components/Footer/Footer';
import ModalComponent from '../../components/ModalComponent/ModalComponent';

const LandingPage = (props) => {
  const {
    posts, handleNewPost, handleModalOpenState, isModalOpen, isFormSubmitted, setIsFormSubmitted,
  } = props;

  const renderPosts = posts.map((post) => (
    <Card key={post.id} post={post} />
  ));

  return (
    <div className="LandingPage">
      <Header />
      <div className="LandingPage-posts">
        <div className="LandingPage-side-section" />
        <Masonry
          className="LandingPage-main-section"
        >
          {renderPosts}
        </Masonry>
        <div className="LandingPage-side-section" />
      </div>
      <button type="button" onClick={() => handleModalOpenState(true)} className="LandingPage-add-post">
        <span className="material-icons">
          add_circle
        </span>
      </button>
      <ModalComponent
        isModalOpen={isModalOpen}
        handleModalClose={() => handleModalOpenState(false)}
        handleNewPost={(title, content) => handleNewPost(title, content)}
        isFormSubmitted={isFormSubmitted}
        setIsFormSubmitted={(state) => setIsFormSubmitted(state)}
      />
      <Footer />
    </div>
  );
};

LandingPage.propTypes = {
  handleNewPost: PropTypes.func.isRequired,
  handleModalOpenState: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  setIsFormSubmitted: PropTypes.func.isRequired,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    }),
  ),
};

LandingPage.defaultProps = {
  posts: [],
};

export default LandingPage;
